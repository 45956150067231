import { Box, Stack, StackProps } from '@mui/material'
import FixedContainer from 'components/FixedContainer'
import { useDrawerContext } from './Root'

interface FixedProps extends StackProps {
  moveEffect?: boolean
  offset?: string
}

export default function Fixed({
  children,
  moveEffect,
  offset,
  ...rest
}: FixedProps) {
  const { isOpen } = useDrawerContext()

  return (
    <Box>
      <Box height={offset ? offset : '62px'} />
      <FixedContainer
        zIndex={rest.zIndex}
        borderRadius="12px"
        borderColor="primary"
      >
        <Stack
          justifyContent="center"
          padding={isOpen ? '8px 0' : '0'}
          sx={{
            animation: moveEffect ? 'moveUp 500ms linear 3' : '',
            '@keyframes moveUp': {
              '0%': { transform: 'translateY(0)' },
              '50%': { transform: 'translateY(-20px)' },
              '100%': { transform: 'translateY(0)' },
            },
          }}
          {...rest}
        >
          {children}
        </Stack>
      </FixedContainer>
    </Box>
  )
}
