import { Stack, StackProps, useTheme } from '@mui/material'

export interface ContainerFixedProps extends StackProps {
  borderColor?: 'success' | 'error' | 'warning' | 'primary' | 'none'
  fixIn?: 'top' | 'bottom' | 'none'
}

export default function ContainerFixed(props: ContainerFixedProps) {
  const { children, fixIn = 'bottom', borderColor = 'primary', ...rest } = props
  const theme = useTheme()

  const borderColorMapping: Record<string, string> = {
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    primary: theme.palette.primary.main,
  }

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: fixIn === 'top' ? 0 : fixIn,
        bottom: fixIn === 'bottom' ? 0 : fixIn,
        left: 0,
        right: 0,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderTop: `solid 1px ${borderColorMapping[borderColor]}`,
        boxShadow: '0px -4px 8px 0px rgba(0,0,0,0.10)',
        // zIndex: 1,
        transition: 'ease-in 200ms',
      }}
      {...rest}
    >
      {children}
    </Stack>
  )
}
