import { Typography, TypographyProps, useTheme } from '@mui/material'

export default function Subtitle({ children, ...rest }: TypographyProps) {
  const theme = useTheme()
  return (
    <Typography
      variant="h5"
      color={theme.palette.grey[500]}
      fontWeight="bold"
      marginTop={0.5}
      {...rest}
    >
      {children}
    </Typography>
  )
}
