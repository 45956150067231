import { ReactNode } from 'react'
import { Box, LinearProgress, SxProps, useTheme } from '@mui/material'

import FixedContainer, { ContainerFixedProps } from 'components/FixedContainer'
import Container from 'components/Container'

interface FooterProps {
  isLoading?: boolean
  children: ReactNode
  fixedContainerProps?: ContainerFixedProps
  containerSx?: SxProps
}

export default function FloatFooter(props: FooterProps) {
  const { isLoading, fixedContainerProps, containerSx, children } = props
  const theme = useTheme()

  return (
    <FixedContainer {...fixedContainerProps}>
      <Box
        sx={{
          paddingX: 2,
          paddingY: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: `solid 1px ${theme.palette.grey[300]}`,
        }}
      >
        {isLoading && (
          <LinearProgress
            sx={{ position: 'absolute', height: 2, top: 0, left: 0, right: 0 }}
          />
        )}
        <Container fullHeight={false} overflow="visible" customSx={containerSx}>
          {children}
        </Container>
      </Box>
    </FixedContainer>
  )
}
