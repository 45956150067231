import {
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material'
import notifyImage from 'assets/images/notify.svg'

interface EmptyDataProps extends StackProps {
  description: string
  instructionText?: string
  imageWidth?: string
  descriptionProps?: TypographyProps
  instructionTextProps?: TypographyProps
}

export default function EmptyData(props: EmptyDataProps) {
  const {
    description,
    instructionText,
    imageWidth,
    descriptionProps,
    instructionTextProps,
    ...rest
  } = props

  const theme = useTheme()

  return (
    <Stack
      gap={3}
      position="absolute"
      bottom={0}
      top={0}
      left={0}
      right={0}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      paddingX={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
      {...rest}
    >
      <img
        src={notifyImage}
        alt="Imagem de notificação"
        width={imageWidth ? imageWidth : '30%'}
      />

      <Stack spacing={1}>
        <Typography
          variant="h1"
          component="h2"
          fontWeight="medium"
          {...descriptionProps}
        >
          {description}
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.grey[500]}
          fontWeight="bold"
          {...instructionTextProps}
        >
          {instructionText}
        </Typography>
      </Stack>
    </Stack>
  )
}
