import { Typography, TypographyProps } from '@mui/material'

export default function Title(props: TypographyProps) {
  return (
    <Typography
      variant="h4"
      fontWeight="bold"
      component="h2"
      paddingTop={1}
      {...props}
    />
  )
}
