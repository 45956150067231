import {
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
} from '@mui/icons-material'
import { Stack } from '@mui/material'

import IconButton from 'components/IconButton'
import { useDrawerContext } from './Root'

export default function ToggleAction() {
  const { isOpen, onToggleDrawer } = useDrawerContext()

  return (
    <Stack width="100%" direction="row" justifyContent="center">
      <IconButton customSx={{ marginTop: '-44px' }} onClick={onToggleDrawer}>
        {isOpen ? (
          <KeyboardDoubleArrowDownIcon fontSize="medium" />
        ) : (
          <KeyboardDoubleArrowUpIcon
            fontSize="medium"
            sx={{
              animation: 'fade 400ms linear infinite',
              '@keyframes fade': {
                '0%': { opacity: 1 },
                '50%': { opacity: 0 },
                '100%': { opacity: 1 },
              },
            }}
          />
        )}
      </IconButton>
    </Stack>
  )
}
