import { ReactNode } from 'react'
import { LinearProgress, Stack, StackProps } from '@mui/material'
import EmptyData from 'components/EmptyData'

interface ContentProps extends StackProps {
  children: ReactNode
  isEmpty?: boolean
  isLoading?: boolean
}

export default function Content({
  children,
  isEmpty,
  isLoading,
  ...rest
}: ContentProps) {
  return (
    <Stack
      component="main"
      height="100%"
      overflow="auto"
      padding={1.5}
      position={isEmpty ? 'relative' : 'unset'}
      {...rest}
    >
      {isLoading && (
        <LinearProgress
          sx={{ height: '3px', position: 'absolute', inset: 0 }}
        />
      )}

      {!isLoading && isEmpty && (
        <EmptyData
          description="Nenhum registro foi encontrado"
          position="relative"
          marginY="64px"
        />
      )}

      {children}
    </Stack>
  )
}
