import { useEffect } from 'react'
import { Drawer, DrawerProps, Stack, StackProps } from '@mui/material'
import { useDrawerContext } from './Root'

type FullProps = {
  height?: '100vh' | 'auto' | '100%' | number
} & StackProps &
  DrawerProps

export default function Full(props: FullProps) {
  const { open: _open, children, height = '100%', ...rest } = props
  const { isOpen, onOpenDrawer, onCloseDrawer } = useDrawerContext()

  useEffect(() => {
    if (_open === true) {
      onOpenDrawer()
    }
    if (_open === false) {
      onCloseDrawer()
    }
  }, [_open])

  return (
    <Drawer
      anchor="bottom"
      onClose={onCloseDrawer}
      PaperProps={{
        style: {
          padding: isOpen ? '8px 0 8px 0' : '',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          height,
        },
      }}
      open={isOpen}
      {...rest}
    >
      <Stack {...rest}>{children}</Stack>
    </Drawer>
  )
}
