import { useEffect, useState } from 'react'
import { isCPFOrCNPJ } from 'brazilian-values'
import { useNavigate } from 'react-router-dom'
import { Grid, Stack, TextField } from '@mui/material'

import { onlyNumbers, useDialog, useForm } from '@data-c/hooks'
import { ButtonContainer, FormContainer } from '@data-c/ui'

import InscricaoEstadualTextField from 'components/InscricaoEstadualTextField'
import AutoCompleteCidade from 'components/AutoCompleteCidade'
import TelefoneTextField from 'components/TelefoneTextField'
import CnpjCpfTextField from 'components/CnpjCpfTextField'
import AutoCompleteUF from 'components/AutoCompleteUF'
import CEPTextField from 'components/CEPTextField'
import DeleteDrawer from 'components/DeleteDrawer'
import FloatFooter from 'components/FloatFooter'
import DatePicker from 'components/DatePicker'
import CheckBox from 'components/CheckBox'
import Button from 'components/Button'
import DropDownIndicadorIE, {
  IndicadorIEEnum,
} from 'components/DropDownIndicadorIE'

import useCliente, { ClienteModel } from 'hooks/queries/useCliente'
import { CidadeModel, UFModel } from 'hooks/queries/useCidade'
import { CnpjModel } from 'hooks/queries/useConsultaCnpj'
import { CepModel } from 'hooks/queries/useConsultaCep'
import useValidations from 'hooks/useValidation'

import * as yup from 'yup'

const defaultData = {
  nome: '',
  nomeFantasia: '',
  logradouro: '',
  bairro: '',
  cidadeId: '',
  cidadeNome: '',
  cidadeUF: '',
  documentoId: '',
  numero: '',
  complemento: null,
  cidadeUFCodigoIBGE: '',
  paisId: '',
  paisNome: '',
  cep: '',
  telefone: null,
  inscEstadual: null,
  inscEstadualId: IndicadorIEEnum.NAO_INFORMADO_0,
  email: null,
  ativo: false,
}

const schema = yup.object().shape({
  documentoId: yup
    .string()
    .required('Informe um CNPJ/CPF')
    .min(11, 'Informe 11 caracteres para CPF ou 14 para CNPJ')
    .test(
      'CPF/CNPJ-Validator',
      'Informe um CNPJ/CPF válido',
      (documentoId: any) => {
        return isCPFOrCNPJ(documentoId)
      },
    ),
  nome: yup.string().required('Informe o nome'),
  cep: yup
    .string()
    .length(8, 'O CEP deve ser composto por 8 caracteres')
    .nullable(),
  cidadeUF: yup.string().required('Informe o UF'),
  cidadeId: yup.string().required('Informe a Cidade'),
  inscEstadual: yup
    .string()
    .nullable()
    .when('inscEstadualId', (inscEstadualId, schema) =>
      parseInt(inscEstadualId) === IndicadorIEEnum.CONTRIBUINTE_ICMS_1
        ? schema.required('Informe a Inscrição Estadual')
        : schema,
    ),
})

interface FormProps {
  cliente: ClienteModel
  cadastroOrigem: string
}

export default function Form(props: FormProps) {
  const { cliente, cadastroOrigem } = props

  const navigate = useNavigate()

  const { setValidationErrors, validationProps } = useValidations()
  const { useCreateOrUpdateCliente, useRemoverCliente } = useCliente()
  const { mutateAsync: removerCliente, isLoading: isDeletingCliente } =
    useRemoverCliente()
  const { mutateAsync: createOrUpdateCliente, isLoading } =
    useCreateOrUpdateCliente()

  const { isOpen: isOpenDialog, closeDialog, openDialog } = useDialog()

  const { data, handleChange, changeValue, setData } =
    useForm<ClienteModel>(defaultData)

  const [podeBuscar, setPodeBuscar] = useState(true)

  useEffect(() => {
    if (!cliente?.id) {
      setData({
        ...data,
        ativo: true,
      })
    }
  }, [])

  useEffect(() => {
    setPodeBuscar(false)
    if (cliente?.id) {
      const ufModel = {
        id: cliente?.cidadeUF || '',
        codigoIBGE: cliente?.cidadeUFCodigoIBGE || '',
      }

      const cidadeModel = {
        id: cliente?.cidadeId || '',
        nome: cliente?.cidadeNome || '',
        ufId: cliente?.cidadeUF || '',
        paisId: cliente?.paisId || '',
        paisNome: cliente?.paisNome || '',
        uf: ufModel || null,
        pais: {
          id: cliente?.paisId || '',
          nome: cliente?.paisNome || '',
        },
      }

      const inscEstadual =
        cliente?.inscEstadualId === 2 ? 'ISENTO' : cliente?.inscEstadual
      const telefone = onlyNumbers(cliente?.telefone || '')

      setData({
        ...cliente,
        cidadeModel,
        ufModel,
        inscEstadual,
        telefone,
      })
    }
  }, [cliente])

  async function handleRemoverCliente() {
    await removerCliente(data)
    navigate(-1)
  }

  function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        const inscEstadual =
          data?.inscEstadual === 'ISENTO' || data?.inscEstadual?.length === 0
            ? null
            : data?.inscEstadual

        await createOrUpdateCliente({
          cliente: { ...data, inscEstadual },
          cadastroOrigem,
        })
        navigate(-1)
      })
      .catch((err) => {
        setValidationErrors(err)
      })
  }

  const isIsento = (inscricaoEstadualId: number) =>
    [
      IndicadorIEEnum.CONTRIBUINTE_ISENTO_INSCRICAO_2,
      IndicadorIEEnum.NAO_CONTRIBUINTE_PODE_OU_NAO_POSSUIR_9,
    ].includes(inscricaoEstadualId)

  return (
    <>
      <FormContainer view="plain" actions={<></>}>
        <Stack padding={2} paddingBottom={13}>
          <Grid container spacing={2}>
            <Grid item md={2} sm={3} xs={12}>
              <TextField
                disabled
                InputLabelProps={{ shrink: true }}
                name="codigo"
                label="Código"
                value={data.codigo}
              />
            </Grid>
            <Grid item md={4} sm={9} xs={12}>
              <CnpjCpfTextField
                name="documentoId"
                required
                value={data.documentoId}
                onChange={(e) => {
                  setPodeBuscar(true)
                  const documentoId = onlyNumbers(e.target.value)
                  changeValue('documentoId', documentoId)
                }}
                inputProps={{ label: 'CPF/CNPJ', inputMode: 'numeric' }}
                podePesquisarCnpj={podeBuscar}
                onFindCnpj={(cnpj: CnpjModel) => {
                  if (cnpj) {
                    setData({
                      ...data,
                      cep: cnpj.cep,
                      nome: cnpj.nome,
                      email: cnpj.email || null,
                      logradouro: cnpj.logradouro,
                      complemento: cnpj.complemento || null,
                      numero: cnpj.numero,
                      bairro: cnpj.bairro,
                      nomeFantasia: cnpj.nomeFantasia,
                      cidadeModel: cnpj?.cidadeModel || null,
                      cidadeId: cnpj?.cidadeModel?.id || '',
                      cidadeNome: cnpj?.cidadeModel?.nome || '',
                      ufModel: cnpj?.ufModel || null,
                      cidadeUFCodigoIBGE: cnpj?.ufModel?.codigoIBGE || '',
                      cidadeUF: cnpj?.ufModel?.id || '',
                      paisId: cnpj?.cidadeModel?.paisId || '',
                    })
                  }
                }}
                {...validationProps('documentoId')}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InscricaoEstadualTextField
                name="inscEstadual"
                label="Inscrição Estadual"
                value={data?.inscEstadual || ''}
                isentoIE={isIsento(Number(data?.inscEstadualId))}
                onChange={(inscEstadual) => {
                  if (inscEstadual === 'ISENTO') {
                    setData({
                      ...data,
                      inscEstadual: 'ISENTO',
                      inscEstadualId:
                        IndicadorIEEnum.NAO_CONTRIBUINTE_PODE_OU_NAO_POSSUIR_9,
                    })

                    return
                  }

                  if (
                    data?.inscEstadualId !== IndicadorIEEnum.CONTRIBUINTE_ICMS_1
                  ) {
                    setData({
                      ...data,
                      inscEstadual,
                      inscEstadualId: IndicadorIEEnum.NAO_INFORMADO_0,
                    })
                    return
                  }

                  setData({
                    ...data,
                    inscEstadual,
                  })
                }}
                {...validationProps('inscEstadual')}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <DropDownIndicadorIE
                name="inscEstadualId"
                label="Indicador Insc. Estadual"
                value={data?.inscEstadualId}
                onChange={(e) => {
                  const inscEstadualId = parseInt(e.target.value)
                  changeValue('inscEstadualId', inscEstadualId)

                  if (isIsento(inscEstadualId)) {
                    changeValue('inscEstadual', 'ISENTO')
                    return
                  }

                  if (inscEstadualId !== IndicadorIEEnum.CONTRIBUINTE_ICMS_1) {
                    changeValue('inscEstadual', null)
                  }
                }}
                {...validationProps('inscEstadualId')}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TextField
                name="nome"
                label="Nome"
                required
                value={data?.nome}
                inputProps={{ maxLength: 60 }}
                onChange={handleChange}
                {...validationProps('nome')}
              />
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              <TextField
                name="nomeFantasia"
                label="Nome Fantasia"
                inputProps={{ maxLength: 60 }}
                value={data?.nomeFantasia}
                onChange={handleChange}
              />
            </Grid>
            <Grid item lg={2} sm={3} xs={12}>
              <CEPTextField
                name="cep"
                inputProps={{ label: 'CEP', inputMode: 'numeric' }}
                value={data?.cep || ''}
                onChange={(e) => {
                  setPodeBuscar(true)
                  changeValue('cep', onlyNumbers(e.target.value) || null)
                }}
                podePesquisarCep={podeBuscar}
                onFindCep={(cep: CepModel) => {
                  if (cep) {
                    setData({
                      ...data,
                      logradouro: cep.logradouro,
                      bairro: cep.bairro,
                      complemento: cep?.complemento || null,
                      cidadeModel: cep?.cidadeModel,
                      cidadeId: cep?.cidadeModel?.id || '',
                      cidadeNome: cep?.cidadeModel?.nome || '',
                      ufModel: cep?.uf,
                      cidadeUFCodigoIBGE: cep?.uf?.codigoIBGE || '',
                      cidadeUF: cep?.uf?.id || '',
                      paisId: cep?.cidadeModel?.paisId || '',
                    })
                  }
                }}
                {...validationProps('cep')}
              />
            </Grid>
            <Grid item lg={5} sm={3} xs={12}>
              <AutoCompleteUF
                name="cidadeUF"
                label="UF"
                required
                value={data?.ufModel || null}
                onChange={(_, value: UFModel | null) => {
                  const cidadeUF = value?.id
                  const ufModel = value || null
                  const cidadeUFCodigoIBGE = value?.codigoIBGE || ''

                  setData({
                    ...data,
                    ufModel,
                    cidadeUF,
                    cidadeUFCodigoIBGE,
                    cidadeId: '',
                    cidadeModel: null,
                  })
                }}
                {...validationProps('cidadeUF')}
              />
            </Grid>
            <Grid item lg={5} sm={6} xs={12}>
              <AutoCompleteCidade
                name="cidadeId"
                label="Cidade"
                required
                value={data?.cidadeModel}
                uf={data?.cidadeUF || ''}
                onChange={(_, value: CidadeModel | null) => {
                  const cidadeId = value?.id || ''
                  const cidadeNome = value?.nome || ''
                  const cidadeModel = value || null
                  const paisId = value?.paisId || ''

                  setData({
                    ...data,
                    cidadeId,
                    cidadeNome,
                    cidadeModel,
                    paisId,
                  })
                }}
                {...validationProps('cidadeId')}
              />
            </Grid>
            <Grid item lg={5} sm={6} xs={12}>
              <TextField
                name="bairro"
                label="Bairro"
                inputProps={{ maxLength: 60 }}
                value={data?.bairro}
                onChange={handleChange}
              />
            </Grid>
            <Grid item lg={5} md={4} sm={6} xs={12}>
              <TextField
                name="logradouro"
                label="Logradouro"
                value={data?.logradouro}
                inputProps={{ maxLength: 40 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item lg={2} sm={3} xs={12}>
              <TextField
                name="numero"
                label="Número"
                value={data?.numero}
                onChange={handleChange}
                inputProps={{ maxLength: 60, inputMode: 'numeric' }}
              />
            </Grid>
            <Grid item lg={9} md={5} sm={9} xs={12}>
              <TextField
                name="complemento"
                label="Complemento"
                value={data?.complemento || ''}
                onChange={(e) => {
                  const complemento = e.target.value
                  changeValue('complemento', complemento || null)
                }}
                inputProps={{ maxLength: 60 }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <TelefoneTextField
                label="Telefone"
                name="telefone"
                maxLength={14}
                inputProps={{
                  inputMode: 'numeric',
                }}
                value={data?.telefone || ''}
                onChange={(e) => {
                  const telefone = onlyNumbers(e.target.value)
                  changeValue('telefone', telefone || null)
                }}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                name="email"
                label="E-mail"
                inputProps={{ maxLength: 200 }}
                InputLabelProps={{ shrink: true }}
                value={data?.email || ''}
                onChange={(e) => {
                  changeValue('email', e.target.value || null)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="observacao"
                label="Observação"
                value={data?.observacao || ''}
                onChange={(e) =>
                  changeValue('observacao', e.target.value || null)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                name="dataNascimento"
                label="Data de Nascimento"
                value={data?.dataNascimento || null}
                onChange={(date) => changeValue('dataNascimento', date)}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckBox
                label="Ativo"
                name="ativo"
                onChange={handleChange}
                checked={Boolean(data?.ativo)}
              />
            </Grid>
          </Grid>
        </Stack>
      </FormContainer>

      <FloatFooter fixedContainerProps={{ zIndex: 99 }}>
        <ButtonContainer sx={{ width: '100%' }}>
          {data?.id && (
            <Button
              fullWidth
              color="error"
              onClick={openDialog}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Excluir
            </Button>
          )}
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            isLoading={isLoading}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {data?.id ? 'Salvar' : 'Cadastrar'}
          </Button>
        </ButtonContainer>
      </FloatFooter>

      <DeleteDrawer
        isOpen={isOpenDialog}
        isLoading={isDeletingCliente}
        onClose={closeDialog}
        onConfirm={handleRemoverCliente}
      />
    </>
  )
}
