import { useEffect } from 'react'
import {
  Collapse as MuiCollapse,
  CollapseProps as MuiCollapseProps,
  Stack,
  StackProps,
} from '@mui/material'
import { useDrawerContext } from './Root'

type CollapseProps = {
  isCollapsed?: boolean
} & Omit<MuiCollapseProps, 'in'> &
  StackProps

export default function Collapse(props: CollapseProps) {
  const { isCollapsed, children, ...rest } = props

  const { isOpen, onOpenDrawer, onCloseDrawer } = useDrawerContext()

  useEffect(() => {
    if (isCollapsed === true) {
      onOpenDrawer()
    }
    if (isCollapsed === false) {
      onCloseDrawer()
    }
  }, [isCollapsed])

  return (
    <MuiCollapse in={isOpen} {...rest}>
      <Stack padding={1.5} {...rest}>
        {children}
      </Stack>
    </MuiCollapse>
  )
}
