import { cloneElement, ReactElement } from 'react'
import { useDrawerContext } from './Root'

interface TriggerProps {
  children: ReactElement
  disabled?: boolean
}

export default function Trigger({ children, disabled }: TriggerProps) {
  const { onToggleDrawer } = useDrawerContext()
  return cloneElement(children, {
    onClick: !disabled ? onToggleDrawer : () => {},
    style: { cursor: !disabled ? 'pointer' : 'not-allowed', opacity: 0.8 },
  })
}
