import ToggleAction from './ToggleAction'
import ActionClose from './ActionClose'
import Subtitle from './Subtitle'
import Collapse from './Collapse'
import Trigger from './Trigger'
import Toolbar from './Toolbar'
import Content from './Content'
import Header from './Header'
import Fixed from './Fixed'
import Title from './Title'
import Root from './Root'
import Full from './Full'

const Drawer = {
  ToggleAction,
  ActionClose,
  Collapse,
  Subtitle,
  Content,
  Trigger,
  Toolbar,
  Header,
  Fixed,
  Title,
  Root,
  Full,
}

export default Drawer
