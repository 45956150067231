import { ReactNode } from 'react'
import { Stack } from '@mui/material'

interface ToolbarProps {
  children: ReactNode
}

export default function Toolbar({ children }: ToolbarProps) {
  return (
    <Stack
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {children}
    </Stack>
  )
}
