import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import ErrorInterface from 'interfaces/ErrorInterface'
import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import api from 'services/api'
import cadastroApi from 'services/cadastro-api'
import { CidadeModel, UFModel } from './useCidade'
import useNotification from 'hooks/useNotification'
import { IndicadorIEEnum } from 'components/DropDownIndicadorIE'

export interface ClienteModel {
  id?: string
  codigo?: number
  nome: string
  nomeFantasia?: string
  logradouro?: string
  bairro?: string
  cidadeId?: string
  cidadeNome?: string
  cidadeModel?: CidadeModel | null
  cidadeUF?: string
  ufModel?: UFModel | null
  documentoId: string
  numero?: string
  complemento?: string | null
  cidadeUFCodigoIBGE?: string
  paisId?: string
  paisNome?: string
  cep?: string
  telefone?: string | null
  inscEstadual?: string | null
  inscEstadualId?: IndicadorIEEnum | null
  email?: string | null
  ativo?: boolean
  observacao?: string | null
  dataNascimento?: string | null
  createdDate?: string
  updatedDate?: string
}

const notificationBottomStyle = { style: { bottom: '80px' } }

export async function obterClientes(
  query: string,
): Promise<HttpResponseInterface<ClienteModel>> {
  const response = await api.get<HttpResponseInterface<ClienteModel>>(
    `Pessoa/Pesquisar/${query}`,
  )

  return response.data
}

export async function obterClientePorId(
  id?: string,
): Promise<ClienteModel | null> {
  if (!id) return null
  const response = await cadastroApi.get<{ data: ClienteModel }>(`Pessoa/${id}`)
  return response.data.data
}

export async function createOrUpdateCliente(
  data: ClienteModel,
): Promise<HttpResponseInterface<ClienteModel>> {
  delete data.cidadeModel
  delete data.ufModel

  if (data?.id) {
    const response = await cadastroApi.put(`Pessoa/${data.id}`, data)
    return response.data
  }

  const response = await cadastroApi.post('Pessoa', data)
  return response.data
}

export async function removerCliente(
  data: ClienteModel,
): Promise<ClienteModel> {
  const response = await cadastroApi.delete(`Pessoa/${data.id}`)
  return response.data
}

export function useQueryObterClientes(query: string) {
  return useQuery<
    HttpResponseInterface<ClienteModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['CLIENTE', query], () => {
    return obterClientes(query)
  })
}

export function useQueryObterClientePorId(id?: string) {
  return useQuery<ClienteModel | null, AxiosError>(['CLIENTE', id], () => {
    return obterClientePorId(id)
  })
}

export function useCreateOrUpdateCliente() {
  const notifications = useNotification()

  return useMutation<
    HttpResponseInterface<ClienteModel>,
    AxiosError,
    {
      cadastroOrigem: string
      cliente: ClienteModel
    }
  >((data) => createOrUpdateCliente(data.cliente), {
    onSuccess(_, data) {
      const flexUpdateOrCreate = data?.cliente.id ? 'alterado' : 'adicionado'

      const hasEstiloAplicado = data.cadastroOrigem.startsWith('/clientes')
        ? notificationBottomStyle
        : undefined

      notifications.notifySuccess(
        `Cliente ${flexUpdateOrCreate} com sucesso!`,
        hasEstiloAplicado,
      )
    },
    onError(error) {
      notifications.notifyException(error, notificationBottomStyle)
    },
  })
}

export function useRemoverCliente() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<ClienteModel, AxiosError, ClienteModel>(
    (data) => removerCliente(data),
    {
      onSuccess() {
        notifications.notifySuccess(
          'Cliente excluído com sucesso!',
          notificationBottomStyle,
        )
        queryClient.invalidateQueries(['CLIENTE'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useCliente() {
  return {
    useQueryObterClientes,
    useQueryObterClientePorId,
    useCreateOrUpdateCliente,
    useRemoverCliente,
  }
}
