import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formatToCPFOrCNPJ } from 'brazilian-values'

import { Add as AddIcon } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { formatCurrency } from '@data-c/hooks'

import ContentContainer from 'components/Container/components/ContentContainer'
import PageTitle from 'components/PageTitle'
import Container from 'components/Container'
import CardInfo from 'components/CardInfo'
import Button from 'components/Button'

import { useNFContext } from 'contexts/NFContext/NFContext'

import useCapitalize from 'hooks/useCapitalize'
import useNF from 'hooks/queries/useNF'

import CardOutrasInformacoes from './components/CardOutrasInformacoes'
import CardFormasPagamento from './components/CardFormasPagamento'
import CardDataSaida from './components/CardDataSaida'
import Actions from './components/Actions'

export default function DetalhamentoNF() {
  const { nfId } = useParams()
  const navigate = useNavigate()

  const { setNFIds } = useNFContext()
  const { capitalizeFirstLetter } = useCapitalize()

  const { useQueryObterNFPorId } = useNF()
  const {
    data: nf,
    isLoading: isLoadingObterNFPorId,
    isFetching: isFetchingObterNFPorId,
    error,
  } = useQueryObterNFPorId(nfId || '')

  const dataFormatada = useMemo(() => {
    const endereco = `${nf?.pessoa?.logradouro || ''}, ${
      nf?.pessoa?.cidadeNome || ''
    } - ${nf?.pessoa?.cidadeUF || ''}`

    return {
      ...nf,
      operacaoNome: nf?.operacao?.nome || '',
      clienteNome: nf?.pessoa?.nome || '',
      totalNFFormatado: formatCurrency(nf?.totalNF || 0),
      pagamentoTotalFormatado: formatCurrency(nf?.pagamentoTotal || 0),
      pagamentoTrocoFormatado: formatCurrency(nf?.pagamentoTroco || 0),
      documentoIdFormatado: formatToCPFOrCNPJ(nf?.pessoa?.documentoId || ''),
      enderecoFormatado: capitalizeFirstLetter(endereco),
      descontoFormatado: formatCurrency(nf?.totalDesconto || 0),
    }
  }, [nf])

  function handleNavigateConfigurarPagamento(nfPagamentoId: string) {
    setNFIds('nfPagamentoId', nfPagamentoId)
    navigate(`/NF/${nf?.id}/configurar-pagamento`)
  }

  function handleNavigateProdutos() {
    navigate(`/NF/${nf?.id}/produtos`)
  }

  function handleNavigateFormaPagamento() {
    navigate(`/NF/${nf?.id}/forma-de-pagamento`)
  }

  return (
    <Container paper>
      <PageTitle
        title="Detalhes da venda"
        onBack={handleNavigateProdutos}
        onClose={() => navigate('/vendas')}
      />

      <ContentContainer
        isLoading={isLoadingObterNFPorId}
        error={error}
        customSx={{ justifyContent: 'initial', marginBottom: 30, gap: 1 }}
      >
        <CardDataSaida nf={nf} />
        <CardInfo
          title="Operação"
          subtitle={dataFormatada.operacaoNome || ''}
        />
        <CardInfo title="Cliente" subtitle={dataFormatada.clienteNome || ''}>
          <Typography variant="body2">
            {dataFormatada.documentoIdFormatado}
          </Typography>
          <Typography variant="body2">
            {dataFormatada.enderecoFormatado}
          </Typography>
        </CardInfo>
        <CardInfo title="Formas de Pagamento" subtitle="">
          <CardFormasPagamento
            nfPagamentos={nf?.nfPagamentos || []}
            onClick={handleNavigateConfigurarPagamento}
            isLink
          />

          <Button
            fullWidth
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={handleNavigateFormaPagamento}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Adicionar nova forma de pagamento
          </Button>
        </CardInfo>

        <CardOutrasInformacoes nf={nf} />
      </ContentContainer>

      <Actions
        nf={nf}
        totalNFFormatado={dataFormatada.totalNFFormatado}
        pagamentoTotalFormatado={dataFormatada.pagamentoTotalFormatado}
        descontoFormatado={dataFormatada.descontoFormatado}
        pagamentoTrocoFormatado={dataFormatada.pagamentoTrocoFormatado}
        totalDesconto={nf?.totalDesconto || 0}
        pagamentoTroco={nf?.pagamentoTroco || 0}
        isFetchingNF={isFetchingObterNFPorId}
      />
    </Container>
  )
}
