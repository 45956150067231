import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { formatCurrency } from '@data-c/hooks'
import { useNFContext } from 'contexts/NFContext/NFContext'
import { NFModel } from 'hooks/queries/useNF'

import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'
import ButtonContainer from 'components/ButtonContainer'
import FloatDrawer from 'components/FloatDrawer'
import Button from 'components/Button'

interface ActionsProps {
  nf?: NFModel
}

export default function Actions({ nf }: ActionsProps) {
  const { isLoading } = useNFContext()
  const navigate = useNavigate()

  const [isOpenDrawer, setOpenDrawer] = useState(false)

  const itensAdicionados = nf?.nfItems?.length
  const totalNFFormatado = formatCurrency(nf?.totalNF || 0)
  const flexSingularPluralText =
    itensAdicionados && itensAdicionados > 1
      ? ' produtos adicionados'
      : ' produto adicionado'

  function handleNavigateFormaPagamento() {
    if (isLoading) return
    navigate(`/NF/${nf?.id}/forma-de-pagamento`)
  }

  function handleNavigateDetalhamentoNF() {
    if (isLoading) return
    navigate(`/NF/${nf?.id}/detalhamento-nf`)
  }

  return (
    <FloatDrawer
      isOpen={isOpenDrawer}
      onOpenDrawer={() => setOpenDrawer(!isOpenDrawer)}
      headerContent={
        <>
          <Typography variant="body1">
            {itensAdicionados}
            {flexSingularPluralText}
          </Typography>
          <FloatFooterCurrency title="Total" price={totalNFFormatado} />
        </>
      }
      customSx={{ padding: 1.5 }}
    >
      <ButtonContainer margin="12px 0">
        <Button
          variant="contained"
          fullWidth
          disabled={isLoading}
          onClick={handleNavigateFormaPagamento}
        >
          Forma de pagamento
        </Button>

        <Button
          fullWidth
          disabled={isLoading}
          onClick={handleNavigateDetalhamentoNF}
        >
          Detalhes da venda
        </Button>
      </ButtonContainer>
    </FloatDrawer>
  )
}
