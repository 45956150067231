import useDialog from 'hooks/useDialog'
import { createContext, ReactNode, useContext } from 'react'

interface OpenDrawerProps<T> {
  data?: T
}

interface DrawerContextData<T> {
  data?: T
  isOpen: boolean
  onToggleDrawer: () => void
  onOpenDrawer: (data?: OpenDrawerProps<T>) => void
  onCloseDrawer: () => void
}

export const DrawerContext = createContext({} as DrawerContextData<any>)

export default function Root<T>({ children }: { children: ReactNode }) {
  const { data, isOpen, openDialog, toggleDialog, closeDialog } = useDialog<T>()

  function handleOpen(props?: OpenDrawerProps<T>) {
    const { data: _data } = props || {}
    openDialog(_data)
  }

  return (
    <DrawerContext.Provider
      value={{
        data,
        isOpen,
        onToggleDrawer: () => toggleDialog(),
        onOpenDrawer: handleOpen,
        onCloseDrawer: () => closeDialog(),
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}

export function useDrawerContext<T>() {
  return useContext<DrawerContextData<T>>(DrawerContext)
}
