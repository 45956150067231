import { useNavigate } from 'react-router-dom'
import { Box, Stack } from '@mui/material'

import FloatFooterCurrency from 'components/FloatFooter/components/FloatFooterCurrency'
import ButtonContainer from 'components/ButtonContainer'
import Button from 'components/Button'
import Drawer from 'components/Drawer'

import { NFModel } from 'hooks/queries/useNF'

import NFReprocessamento from './components/NFReprocessamento'
import NFFechamento from './components/NFFechamento'

interface ActionsProps {
  nf?: NFModel
  totalNFFormatado: string
  pagamentoTotalFormatado: string
  descontoFormatado: string
  pagamentoTrocoFormatado: string
  totalDesconto: number
  pagamentoTroco: number
  isFetchingNF?: boolean
}

export default function Actions(props: ActionsProps) {
  const {
    nf,
    totalNFFormatado,
    pagamentoTotalFormatado,
    descontoFormatado,
    pagamentoTrocoFormatado,
    totalDesconto,
    pagamentoTroco,
    isFetchingNF = false,
  } = props

  const navigate = useNavigate()

  return (
    <Drawer.Root>
      <Drawer.Fixed>
        <Drawer.Header>
          <Drawer.ToggleAction />
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <FloatFooterCurrency title="Total" price={totalNFFormatado} />
              <FloatFooterCurrency
                title="Total Pagamento"
                price={pagamentoTotalFormatado}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              {totalDesconto > 0 && (
                <FloatFooterCurrency
                  title="Desconto"
                  price={descontoFormatado}
                />
              )}
              {pagamentoTroco > 0 && (
                <FloatFooterCurrency
                  title="Troco"
                  price={pagamentoTrocoFormatado}
                />
              )}
            </Stack>
          </Box>
        </Drawer.Header>

        <Drawer.Collapse>
          <ButtonContainer>
            <NFFechamento nf={nf} isFechingNF={isFetchingNF} />
            <Button
              fullWidth
              onClick={() => navigate(`/NF/${nf?.id}/produtos`)}
            >
              Produtos
            </Button>
            <NFReprocessamento nfId={nf?.id || ''} />
          </ButtonContainer>
        </Drawer.Collapse>
      </Drawer.Fixed>
    </Drawer.Root>
  )
}
