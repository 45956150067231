import { useNavigate, useParams } from 'react-router-dom'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useDialog } from '@data-c/hooks'

import nfeImage from 'assets/images/nfe-image.svg'
import xmlImage from 'assets/images/xml-image.svg'
import boletoImage from 'assets/images/boleto-image.svg'

import { TypeNFModelo } from 'components/Inputs/DropDownNFModelo'
import ContentContainer from 'components/Container/components/ContentContainer'
import DownloadNFDialog from 'components/DownloadNFDialog'
import PageTitle from 'components/PageTitle'
import Container from 'components/Container'
import Button from 'components/Button'

import { useNFContext } from 'contexts/NFContext/NFContext'

import useNF, { NFModel, nfModeloMapping } from 'hooks/queries/useNF'
import useDownload from 'hooks/useDownload'

import { NFStatusEnum } from 'enums/NFStatusEnum'

import ErrorNFDialog from './components/ErrorNFDialog'
import InfoContent from './components/InfoContent'
import CardButton from './components/CardButton'
import Header from './components/Header'
import useCredentials from 'hooks/useCredentials'
import Drawer from 'components/Drawer'

export default function NFFechada() {
  const { nfId } = useParams()
  const { userLogged } = useCredentials()

  const theme = useTheme()
  const navigate = useNavigate()
  const isDesktopVersion = useMediaQuery(() => theme.breakpoints.up('sm'))

  const { mensagemSucesso, setMensagemSucesso, setNFIds } = useNFContext()
  const { useQueryObterNFPorId, useReabrirNF, useTransmitirNF } = useNF()
  const { downloadArquivo } = useDownload()
  const { mutateAsync: transmitirNF, isLoading: isLoadingTransmitirNF } =
    useTransmitirNF()
  const { mutateAsync: reabrirNF, isLoading: isLoadingReabrirNF } =
    useReabrirNF()
  const { data: nf, isFetching, error } = useQueryObterNFPorId(nfId || '')

  const {
    data: dataErrorNFDialog,
    isOpen: isOpenErrorNFDialog,
    closeDialog: closeErrorNFDialog,
    openDialog: openErrorNFDialog,
  } = useDialog<NFModel>()

  const {
    data: dataDownloadNFDialog,
    isOpen: isOpenDownloadNFDialog,
    closeDialog: closeDownloadNFDialog,
    openDialog: openDownloadNFDialog,
  } = useDialog<TypeNFModelo>()

  async function handleReabrirNF() {
    if (nf) {
      await reabrirNF(nf)
      navigate(`/NF/${nf?.id}/detalhamento-nf`)
      setMensagemSucesso(false)
    }
  }

  async function handleTransmitirNF() {
    if (nf) {
      const response = await transmitirNF(nf)

      if (response.status !== NFStatusEnum.TRANSMITIDA) {
        openErrorNFDialog(response)
        return
      }

      setMensagemSucesso(true)
      navigate(`/NF/${nf?.id || ''}/nf-transmitida`)
    }
  }

  function handleBack() {
    setMensagemSucesso(false)
    navigate('/vendas')
  }

  function handleVisualizarPDF(nfModelo: TypeNFModelo) {
    if (nf?.id) {
      if (!isDesktopVersion) {
        openDownloadNFDialog(nfModelo)
        return
      }

      setNFIds('nfId', nf?.id)
      navigate(`/NF/${nf?.id}/visualizar-danfe`, { state: { nfModelo } })
    }
  }

  function handleVisualizarXML() {
    if (nf?.id) {
      if (!isDesktopVersion) {
        const chaveAcesso = nf?.nFe_ChaveAcesso || ''
        const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]

        if (nf?.nFeXML) {
          downloadArquivo(nf?.nFeXML, [chaveAcesso, nfModeloDescricao], 'xml')
        }
        return
      }

      setNFIds('nfId', nf?.id)
      navigate(`/NF/${nf?.id}/visualizar-xml`)
    }
  }

  function handleNavigateToGeracaoDeBoleto() {
    setNFIds('nfId', nf?.id || '')
    navigate(`/NF/${nf?.id}/geracao-de-boleto`)
  }

  if (nf?.status && nf?.status !== NFStatusEnum.FECHADA) {
    handleBack()
    return
  }

  const nfModeloDescricao = nfModeloMapping[nf?.modelo || 0]
  const nfModelo = nf?.modelo === 55 ? 'DANFE' : 'DANFCE'
  const possuePermissaoHubbank = userLogged?.permissoes['stella-hubbank']

  return (
    <Container paper>
      <PageTitle title="" onClose={handleBack} />
      <ContentContainer isLoading={isFetching} error={error}>
        <Box marginBottom="370px">
          {mensagemSucesso && <Header nfModeloDescricao={nfModeloDescricao} />}
          <InfoContent nf={nf} />
        </Box>
      </ContentContainer>

      <Drawer.Root>
        <Drawer.Fixed>
          <Drawer.Header>
            <Drawer.ToggleAction />
          </Drawer.Header>

          <Drawer.Collapse>
            <Stack
              alignItems="center"
              width="100%"
              gap={3}
              marginTop={3}
              padding={3}
            >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <CardButton
                  label={
                    isDesktopVersion
                      ? `Visualizar ${nfModelo}`
                      : `Baixar ${nfModelo}`
                  }
                  image={nfeImage}
                  onClick={() => handleVisualizarPDF(nfModelo)}
                />

                <CardButton
                  label={isDesktopVersion ? `Visualizar XML` : `Baixar XML`}
                  image={xmlImage}
                  onClick={handleVisualizarXML}
                />
                {possuePermissaoHubbank && (
                  <CardButton
                    label="Emitir Boletos"
                    image={boletoImage}
                    height="34px"
                    onClick={handleNavigateToGeracaoDeBoleto}
                  />
                )}
              </Box>

              <Stack gap={1.5} width="100%">
                <Button
                  variant="contained"
                  isLoading={isLoadingTransmitirNF}
                  onClick={handleTransmitirNF}
                >
                  Transmitir
                </Button>
                <Button
                  isLoading={isLoadingReabrirNF}
                  onClick={handleReabrirNF}
                >
                  Abrir {nfModeloDescricao} novamente
                </Button>
                <Button onClick={handleBack}>Voltar às Vendas</Button>
              </Stack>
            </Stack>
          </Drawer.Collapse>
        </Drawer.Fixed>
      </Drawer.Root>

      <ErrorNFDialog
        data={dataErrorNFDialog || ({} as NFModel)}
        isOpen={isOpenErrorNFDialog}
        onClose={closeErrorNFDialog}
      />

      {isOpenDownloadNFDialog && (
        <DownloadNFDialog
          nf={nf || ({} as NFModel)}
          chaveId={dataDownloadNFDialog}
          isOpen={isOpenDownloadNFDialog}
          onClose={closeDownloadNFDialog}
        />
      )}
    </Container>
  )
}
