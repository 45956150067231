import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'
import { useNFContext } from 'contexts/NFContext/NFContext'

import useNF, { NFModel } from 'hooks/queries/useNF'
import useDialog from 'hooks/useDialog'

import DialogXMLError from '../../../DialogXMLError'

interface NFFechamentoProps {
  nf?: NFModel
  isFechingNF?: boolean
}

export default function NFFechamento({ nf, isFechingNF }: NFFechamentoProps) {
  const { setMensagemSucesso } = useNFContext()
  const navigate = useNavigate()

  const {
    isOpen: isOpenXMLErrorDialog,
    closeDialog: closeXMLErrorDialog,
    openDialog: openXMLErrorDialog,
    data: dataXMLErrorDialog,
  } = useDialog<NFModel>()

  const { useFecharNF } = useNF()
  const { mutateAsync: fecharNF, isLoading: isLoadingFecharNF } = useFecharNF()

  async function handleSubmit() {
    if (nf) {
      const responseNF = await fecharNF(nf)
      if (
        responseNF?.nFeErrosSchema &&
        responseNF?.nFeErrosSchema?.length > 0
      ) {
        openXMLErrorDialog(responseNF)
        return
      }

      setMensagemSucesso(true)
      navigate(`/NF/${nf?.id}/nf-fechada`)
    }
  }

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        disabled={isFechingNF}
        isLoading={isLoadingFecharNF}
        onClick={handleSubmit}
        sx={{ whiteSpace: 'nowrap' }}
      >
        Fechar Venda
      </Button>

      {isOpenXMLErrorDialog && dataXMLErrorDialog && (
        <DialogXMLError
          data={dataXMLErrorDialog}
          isOpen={isOpenXMLErrorDialog}
          onClose={closeXMLErrorDialog}
        />
      )}
    </>
  )
}
