import Button from 'components/Button'
import { useDrawerContext } from './Root'

export default function ActionClose() {
  const { onCloseDrawer } = useDrawerContext()
  return (
    <Button variant="text" onClick={onCloseDrawer}>
      Fechar
    </Button>
  )
}
