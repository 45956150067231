import { Box, PaperProps, Stack, StackProps } from '@mui/material'

type HeaderProps = {
  fixed?: boolean
  offset?: string
} & StackProps &
  PaperProps

export default function Header({
  children,
  fixed,
  offset,
  ...rest
}: HeaderProps) {
  return (
    <Box>
      <Box height={fixed && !offset ? '110px' : offset} />
      <Stack
        gap={1}
        padding={1.5}
        {...rest}
        position={fixed ? 'fixed' : 'unset'}
        top={0}
        left={0}
        right={0}
        zIndex={fixed ? 999999 : 1}
        borderRadius={'12px 12px 0 0'}
        sx={{ backgroundColor: 'white' }}
      >
        {children}
      </Stack>
    </Box>
  )
}
